<template>
  <div class="user">
    <el-form :inline="true" :model="formInline" class="demo-form-inline" size="mini">
      <el-form-item label="用户类型">
        <el-select v-model="formInline.type" placeholder="用户类型">
          <el-option label="全部" value="0"></el-option>
          <el-option label="游客" value="1"></el-option>
          <el-option label="注册用户" value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="用户状态">
        <el-select v-model="formInline.status" placeholder="用户状态">
          <el-option label="全部" value="0"></el-option>
          <el-option label="正常" value="1"></el-option>
          <el-option label="禁用" value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="企业名称">
        <el-input v-model="formInline.corporation" placeholder="输入准确的企业名" clearable></el-input>
      </el-form-item>
      <el-form-item label="注册时间">
        <el-date-picker v-model="formInline.datetime" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="tableData" style="width: 100%" border size="mini">
      <el-table-column prop="id" label="ID" width="50"></el-table-column>
      <el-table-column prop="avatar" label="头像" width="65">
        <template slot-scope="scope">
          <div class="avatar-wrap">
            <el-avatar shape="square" :size="30" :src="scope.row.avatar"></el-avatar>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="nickname" label="微信昵称" width="160"></el-table-column>
      <el-table-column prop="name" label="姓名" width="100"></el-table-column>
      <el-table-column prop="cname" label="所属企业"></el-table-column>
      <el-table-column prop="tel" label="手机号" width="100"></el-table-column>
      <el-table-column prop="create_time" label="注册时间" width="150"></el-table-column>
      <el-table-column prop="city" label="城市" width="100"></el-table-column>
      <el-table-column prop="mark" label="备注"></el-table-column>
      <el-table-column prop="status" label="用户状态" width="100">
        <template slot-scope="scope">
          <el-select v-model="scope.row.status" size="mini">
            <el-option label="正常" :value="1"> </el-option>
            <el-option label="禁用" :value="2"> </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150" fixed="right">
        <template slot-scope="scope">
          <!-- <el-button size="mini" @click="handleMark(scope.$index, scope.row)">备注</el-button> -->
          <el-button type="primary" size="mini" @click="handleDetail(scope.row.id)">详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background layout="prev, pager, next, total" :page-size="15" @current-change="handle_page_current" :current-page.sync="page" :total="count"></el-pagination>
    <el-dialog title="添加备注" :visible.sync="markDialogVisible">
      <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="mark" maxlength="50" show-word-limit></el-input>
      <div slot="footer" class="dialog-footer">
        <el-button @click="markDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="markDialogVisible = false">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="用户详情" :visible.sync="detailDialogVisible">
      <el-row>
          <el-col :span="18">
            <el-row class="detai_item">
              <el-col :span="4" style="overflow:hidden;">
                <el-avatar shape="square" :size="100" fit="fill" :src="detail.avatar"></el-avatar>
              </el-col>
              <el-col :span="20">
                <el-col class="user_info" :span="12">微信昵称：{{detail.nickname}}</el-col>
                <el-col class="user_info" :span="12">姓名：{{detail.name}}</el-col>
                <el-col class="user_info" :span="12">用户类型：
                  <span v-if="detail.corporation !== null">注册用户</span>
                  <span v-if="detail.corporation === null">游客用户</span>
                </el-col>
                <el-col class="user_info" :span="12">用户状态：
                  <span v-if="detail.status === 1">正常</span>
                  <span v-if="detail.status === 2">禁用</span>
                </el-col>
                <el-col class="user_info" :span="12">电话： {{detail.tel}}</el-col>
                <el-col class="user_info" :span="12">所在城市： {{detail.city}}</el-col>
                <el-col class="user_info" :span="12">所属企业： {{detail.corporation}}</el-col>
                <el-col class="user_info" :span="12">企业规模：
                  <span v-if="detail.scale == 0">0~20人</span>
                  <span v-if="detail.scale == 1">21~50人</span>
                  <span v-if="detail.scale == 2">51~100人</span>
                  <span v-if="detail.scale == 3">101~500人</span>
                  <span v-if="detail.scale == 4">501~1000人</span>
                  <span v-if="detail.scale == 5">1000人以上</span>
                </el-col>
              </el-col>
            </el-row>
            <el-row class="detai_item">
              <el-col>备注： {{detail.mark}}</el-col>
            </el-row>
            <el-row>
                <el-table :data="detail.address" style="width: 100%" :row-class-name="tableRowClassName">
                  <el-table-column prop="tag" label="标签" width="80"></el-table-column>
                  <el-table-column prop="name" label="收货人" width="100"></el-table-column>
                  <el-table-column prop="tel" label="联系电话" width="130"></el-table-column>
                  <el-table-column prop="detail" label="收货地址"></el-table-column>
                  <el-table-column prop="delete_time" label="删除时间"></el-table-column>
                </el-table>
            </el-row>
          </el-col>
        <el-col :span="6">
          <el-timeline>
            <el-timeline-item :timestamp="detail.create_time">注册时间</el-timeline-item>
            <el-timeline-item :timestamp="detail.visit_time">最新访问时间</el-timeline-item>
            <el-timeline-item :timestamp="detail.order_time">最新下单时间</el-timeline-item>
            <el-timeline-item :timestamp="detail.comment_time">最新评价时间</el-timeline-item>
          </el-timeline>
        </el-col>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="detailDialogVisible = false">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'User',
  data () {
    return {
      page: 1,
      count: 0,
      loading: true,
      formInline: {
        type: '2',
        corporation: '',
        status: '0',
        datetime: []
      },
      mark: '',
      tableData: [],
      detail: {},
      markDialogVisible: false,
      detailDialogVisible: false
    }
  },
  methods: {
    tableRowClassName ({ row, rowIndex }) {
      if (row.default === 1) {
        return 'default_row'
      }
    },
    handleMark (tab, event) {
      this.markDialogVisible = true
      console.log(tab, event)
    },
    handleDetail (id) {
      this.detailDialogVisible = true
      this.getUserInfo(id)
    },
    onSubmit () {
      this.page = 1
      this.getData()
    },
    handle_page_current (e) {
      this.page = e
      this.getData()
    },
    getData () {
      var that = this
      var begin = 0
      var end = 0
      that.loading = true
      if (this.formInline.datetime !== null && this.formInline.datetime.length === 2) {
        begin = this.formInline.datetime[0]
        end = this.formInline.datetime[1]
      }
      this.axios.get('/admin/user', {
        params: {
          page: that.page,
          type: that.formInline.type,
          status: that.formInline.status,
          corporation: that.formInline.corporation,
          begin: begin,
          end: end
        }
      })
        .then(function (response) {
          if (response.data.code === 0) {
            that.tableData = response.data.list
            that.count = response.data.count
            console.log(response.data)
            that.loading = false
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    getUserInfo (id) {
      var that = this
      this.axios.get('/admin/user/detail?id=' + id)
        .then(function (response) {
          if (response.data.code === 0) {
            that.detail = response.data.detail
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  },
  mounted () {
    this.getData()
  }
}
</script>

<style scoped>
.detai_item{
  margin-bottom: 20px;
}
.user_info{
  padding-bottom: 8px;
}
.avatar-wrap{
  max-width: 30px;
  max-height: 30px;
  overflow: hidden;
}
</style>
