<template>
  <div class="main_wrap">
    <div class="main_left">
      <div class="logo_wrap" :style="'width:'+ nav_width +'px'">
        <img class="logo" v-bind:src="logoimage" alt="米城优采">
      </div>
      <div>
        <el-menu :uniqueOpened="true" default-active="1" class="el-menu-vertical-demo" :collapse="isCollapse"
          background-color="#191a23"
          text-color="#fff"
          active-text-color="#ffd04b"
          :router="true"
          >
          <el-menu-item index="1" route="/home">
            <i class="el-icon-menu"></i>
            <span>控制台</span>
          </el-menu-item>
          <el-submenu index="5">
            <template #title>
              <i class="el-icon-s-goods"></i>
              <span>样品管理</span>
            </template>
              <el-menu-item index="5-1" route="/goods">新增样品</el-menu-item>
              <el-menu-item index="5-2" route="/goods_list">所有样品</el-menu-item>
          </el-submenu>
          <el-submenu index="6">
            <template #title>
              <i class="el-icon-receiving"></i>
              <span>分类管理</span>
            </template>
              <el-menu-item index="6-1" route="/category">分类列表</el-menu-item>
          </el-submenu>
          <el-submenu index="7">
            <template #title>
              <i class="el-icon-user-solid"></i>
              <span>用户管理</span>
            </template>
              <el-menu-item index="7-1" route="/user">用户列表</el-menu-item>
          </el-submenu>
          <el-submenu index="8">
            <template #title>
              <i class="el-icon-office-building"></i>
              <span>采购单位</span>
            </template>
              <el-menu-item index="8-2" route="/corporation">单位列表</el-menu-item>
          </el-submenu>
          <el-submenu index="9">
            <template #title>
              <i class="el-icon-s-comment"></i>
              <span>评价管理</span>
            </template>
              <el-menu-item index="9-1" route="/comment">评价列表</el-menu-item>
          </el-submenu>
          <el-submenu index="10">
            <template #title>
              <i class="el-icon-s-order"></i>
              <span>订单管理</span>
            </template>
              <el-menu-item index="10-1" route="/order">订单列表</el-menu-item>
          </el-submenu>
          <el-submenu index="11">
            <template #title>
              <i class="el-icon-truck"></i>
              <span>发货</span>
            </template>
              <el-menu-item index="11-1" route="/tosend">待发货</el-menu-item>
          </el-submenu>
        </el-menu>
      </div>
    </div>
    <div class="main_right" :style="'padding-left:'+ nav_width +'px'">
      <div class="top_header">
        <i class="el-icon-s-unfold switch_icon" @click="handleSwitch"></i>
        <div class="page_title">{{getBreadcrumb}}</div>
      </div>
      <div class="main_content">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Base',
  data () {
    return {
      activeIndex: '1',
      activeIndex2: '1',
      isCollapse: false,
      nav_width: 256,
      logoimage: '/image/logo_2.png',
      logoimagepath: ['/image/logo_1.png', '/image/logo_2.png']
    }
  },
  methods: {
    handleSwitch () {
      this.isCollapse = !this.isCollapse
      if (this.isCollapse) {
        console.log('关')
        this.nav_width = 64
        this.logoimage = this.logoimagepath[0]
      } else {
        console.log('开')
        this.nav_width = 256
        this.logoimage = this.logoimagepath[1]
      }
    }
  },
  computed: {
    getBreadcrumb: function () {
      return this.$route.meta.title
    }
  }
}
</script>

<style>
  #app, body, html{
    height: 100%;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  .main_wrap{
    display: flex;
    height: 100%;
    flex: auto;
    width: 100%;
  }
  .main_left{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    background-color: #191a23;
    box-shadow: 2px 0 6px rgb(0 21 41 / 35%);
    z-index: 99;
    overflow: auto;
  }
  .main_left::-webkit-scrollbar{
    width:0;
  }
  .logo_wrap{
    height: 64px;
    display: flex;
    justify-content: center;
    background-color: #191a23;
    border-bottom: 1px solid #101117;
    position: sticky;
    top: 0;
    z-index: 100;
    transition: width 0.3s;
  }
  .logo{
    height: 32px;
    margin: auto;
  }
  .main_right{
    width: 100%;
    box-sizing: border-box;
    transition: all 0.3s;
  }
  .el-menu {
    border-right: none !important
  }
  .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 256px;
    min-height: 400px;
  }
  .top_header{
    background-color: #ffffff;
    display: flex;
    align-items: center;
    min-height: 64px;
    box-shadow: 0 1px 4px rgb(0 21 41 / 8%);
    position: relative;
    position: sticky;
    z-index: 98;
    top: 0;
  }
  .switch_icon{
    padding: 20px;
  }
  .main_content{
    box-sizing: border-box;
    padding: 20px;
    width: 100%;
  }
  .page_title{
    position: relative;
    padding-left: 20px;
  }
  .page_title::before{
    content: "";
    position: absolute;
    width: 1px;
    height: 16px;
    left: 0px;
    top: 58%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    background-color: #DCDFE6
  }
  .el-dialog .default_row {
    color: red;
  }
  .el-pagination{
  margin-top: 30px;
  text-align: center;
}
</style>
