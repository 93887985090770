import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Home from '../views/Home.vue'
import User from '../views/User.vue'
import Base from '../views/Base.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Base',
    component: Base,
    children: [
      {
        path: '/home',
        name: 'Home',
        component: Home,
        meta: { title: '控制台' }
      },
      {
        path: '/user',
        name: 'User',
        component: User,
        meta: { title: '用户管理 / 用户列表' }
      },
      {
        path: '/about',
        name: 'About',
        meta: { title: '关于' },
        component: () => import('../views/About.vue')
      },
      {
        path: '/goods',
        name: 'Goods',
        meta: { title: '样品管理 / 新增样品' },
        component: () => import('../views/Goods.vue')
      },
      {
        path: '/goods_edit/:id',
        name: 'GoodsEdit',
        meta: { title: '样品管理 / 编辑样品' },
        component: () => import('../views/Goods_edit.vue')
      },
      {
        path: '/goods_list',
        name: 'Goods_list',
        meta: { title: '样品管理 / 样品列表' },
        component: () => import('../views/Goods_list.vue')
      },
      {
        path: '/category',
        name: 'Category',
        meta: { title: '分类管理 / 分类列表' },
        component: () => import('../views/Category.vue')
      },
      {
        path: '/corporation',
        name: 'Corporation',
        meta: { title: '单位管理' },
        component: () => import('../views/Corporation.vue')
      },
      {
        path: '/order',
        name: 'Order',
        meta: { title: '订单管理 / 订单列表' },
        component: () => import('../views/Order.vue')
      },
      {
        path: '/tosend',
        name: 'Tosend',
        meta: { title: '发货管理 / 待发货' },
        component: () => import('../views/Tosend.vue')
      },
      {
        path: '/comment',
        name: 'Comment',
        meta: { title: '评论管理' },
        component: () => import('../views/Comment.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { title: '登录' }
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  var token = sessionStorage.getItem('token')
  if (to.meta.title) {
    document.title = to.meta.title + '|MCYC'
  }
  if (to.name !== 'Login' && token == null) next({ name: 'Login' })
  else next()
})
export default router
